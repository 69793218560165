<template>
    <div class="page">
        <div class="page_header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>顾客之声</el-breadcrumb-item>
                <el-breadcrumb-item>列表</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="page_headerSecondary">
            <div class="search">
                <el-select v-model="value" placeholder="请选择" @change="getData">
                    <el-option v-for="el in opts" :key="el.key" :value="el.key" :label="`${el.key}（${el.value}）`"> </el-option>
                </el-select>
            </div>
            <div></div>
        </div>
        <div class="page_content">
            <el-table :data="tableData" border>
                <el-table-column prop="id" align="center" label="id"> </el-table-column>
                <el-table-column prop="name" align="center" label="名字"> </el-table-column>
                <el-table-column prop="create_time" align="center" label="申请时间"> </el-table-column>
                <el-table-column prop="read_status" align="center" label="是否已读">
                    <template slot-scope="{ row }">
                        <span :style="{ color: row.read_status ? '#ccc' : 'red' }">{{ row.read_status ? "已读" : "未读" }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="{ row }">
                        <div class="_delete">
                            <button @click="del(row)">删除</button>
                        </div>
                        <div class="_look">
                            <button @click="openInfo(row)">详情</button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!-- 分页器 -->
        <vy-pager
            :count="total"
            :page-size="pageSize"
            :page="page"
            @current-change="currentChange"
            @size-change="sizeChange"
        ></vy-pager>
        <!-- 详情弹出框 -->
        <el-dialog
            title="提交表单信息"
            :visible.sync="dialogShow"
            width="500px"
            custom-class="edit"
            :before-close="
                done => {
                    getData();
                    $bus.$emit('updateUnread');
                    done();
                }
            "
        >
            <el-descriptions :column="1">
                <el-descriptions-item label="渠道" v-if="form.channel !== undefined">{{ form.channel }}</el-descriptions-item>
                <el-descriptions-item label="名字" v-if="form.name !== undefined">{{ form.name }}</el-descriptions-item>
                <el-descriptions-item label="电话" v-if="form.phone !== undefined">{{ form.phone }}</el-descriptions-item>
                <el-descriptions-item label="邮箱" v-if="form.email !== undefined">{{ form.email }}</el-descriptions-item>
                <el-descriptions-item label="公司" v-if="form.company !== undefined">{{ form.company }}</el-descriptions-item>
                <el-descriptions-item label="咨询内容" v-if="form.content !== undefined">{{ form.content }}</el-descriptions-item>
                <el-descriptions-item label="模式(客户案例一级分类)" v-if="form.module !== undefined">
                    {{ form.module }}
                </el-descriptions-item>
                <el-descriptions-item label="行业" v-if="form.industry !== undefined">{{ form.industry }}</el-descriptions-item>
                <el-descriptions-item label="预算" v-if="form.budget !== undefined">{{ form.budget }}</el-descriptions-item>
                <el-descriptions-item label="沟通时间" v-if="form.allow !== undefined">{{ form.allow }}</el-descriptions-item>
                <el-descriptions-item label="更多信息" v-if="form.more !== undefined">{{ form.more }}</el-descriptions-item>
                <el-descriptions-item label="城市" v-if="form.city !== undefined">{{ form.city }}</el-descriptions-item>
                <el-descriptions-item label="一级类名" v-if="form.web_cat_title !== undefined">
                    {{ form.web_cat_title[0].first_title }}
                </el-descriptions-item>
                <el-descriptions-item label="二级类名" v-if="form.web_cat_title !== undefined">
                    {{ form.web_cat_title[0].second_title }}
                </el-descriptions-item>
                <el-descriptions-item label="提交时间" v-if="form.create_time !== undefined">
                    {{ form.create_time }}
                </el-descriptions-item>
                <el-descriptions-item label="推荐人返现账号" v-if="form.account !== undefined">
                    {{ form.account }}
                </el-descriptions-item>
                <el-descriptions-item label="推荐人返现账号类型" v-if="form.account_type !== undefined">
                    {{ form.account_type }}
                </el-descriptions-item>
                <el-descriptions-item label="附件" v-if="form.annex !== undefined">
                    <el-link v-if="form.annex" type="info" :href="form.annex" target="_blank">
                        点击预览<i class="el-icon-view el-icon--right"></i>
                    </el-link>
                    <span v-else>无</span>
                </el-descriptions-item>
                <!-- <el-descriptions-item label="测试附件">
                    <el-link type="info" href="http://vytxx.cn//file/annex/db064de189704a12b0683cc961d8df30.pdf" target="_blank"
                        >点击预览<i class="el-icon-view el-icon--right"></i>
                    </el-link>
                </el-descriptions-item> -->
            </el-descriptions>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import Cookies from "js-cookie";
import VyPager from "@/components/customs/VyPager.vue";

export default {
    components: { VyPager },
    data() {
        return {
            tableData: [],
            opts: [],
            value: "全部",
            total: 0,
            pageSize: 10,
            page: 1,
            dialogShow: false,
            form: {},
        };
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            let opts = ["全部", "数字化转型", "申请合作", "贴心专业诊断", "客户案例", "我要开发", "人才招聘", "投递简历"];
            let { data } = await axios.get("/api/mail/show", {
                params: {
                    channel: this.value,
                    page: this.page,
                    pageSize: this.pageSize,
                },
            });
            this.tableData = data.data;
            this.total = data.count;
            this.opts = opts.map(e => {
                return {
                    key: e,
                    value: data[e],
                };
            });
        },
        currentChange(page) {
            this.page = page;
            this.getData();
        },
        sizeChange(pageSize) {
            this.pageSize = pageSize;
            this.getData();
        },
        // 删除
        async del({ id }) {
            let s = await this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => true)
                .catch(() => false);
            if (!s) return;
            let { data } = await axios.delete("/api/mail/del", { params: { id } });
            if (data.code != 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            this.getData();
        },
        // 打开详情弹出框
        openInfo(row) {
            this.dialogShow = true;
            this.form = { ...row };
            // 点击详情发送已读请求
            axios.get("/api/mail/details", { params: { id: row.id } });
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
    }
    .page_headerSecondary {
        display: flex;
        align-items: center;
        margin-top: 20px;
        & > div {
            flex: 1;
        }
    }
    .page_pagination {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        /deep/.el-pagination {
            border-radius: 50px;
            overflow: hidden;
            padding: 0px;
        }
    }
}
</style>
